const getUserPhotos = (payload, callback) => {
    const {albumId} = payload;

    return fetch('https://jsonplaceholder.typicode.com/photos?albumId=' + albumId, {
        crossDomain: true,
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    })
        .then(response => response.json())
        .then(json => callback(json))

}

const getUserDetail = (payload, callback) => {
    const {userID} = payload;

    return fetch('https://jsonplaceholder.typicode.com/users/' + userID, {
        crossDomain: true,
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    })
        .then(response => response.json())
        .then(json => callback(json))
}

export {
    getUserPhotos,
    getUserDetail
}