const getTopMenu = (payload, callback) => {

    return callback([
        {
            ButtonID: 1,
            ButtonCode: 'users',
            ButtonLabel: 'Users'
        },
        {
            ButtonID: 2,
            ButtonCode: 'countries',
            ButtonLabel: 'Countries Stats'
        },
        {
            ButtonID: 3,
            ButtonCode: 'dbi1',
            ButtonLabel: 'Dashboard Item'
        },
        {
            ButtonID: 4,
            ButtonCode: 'dbi2',
            ButtonLabel: 'Dashboard Item'
        },
        {
            ButtonID: 5,
            ButtonCode: 'db3',
            ButtonLabel: 'Dashboard Item'
        },
        {
            ButtonID: 6,
            ButtonCode: 'dbi4',
            ButtonLabel: 'Dashboard Item'
        },
        
    ]);

}

export {
    getTopMenu,
}