import {createAction} from 'redux-actions';
import typeToReducer from 'type-to-reducer';

// ------------------------------------
// Actions
// ------------------------------------

const mainInit = createAction('MAINTINIT');
const saveButtons = createAction('SAVE_BUTTON');
const setLoading = createAction('SET_LOADING');

export const actions = {
  mainInit,
  saveButtons,
  setLoading,
};

// ------------------------------------
// Reducer
// ------------------------------------

export const initialState = {
  topMenuButtons: [],
  loading: false
};

export const saveButtonsHandler = (state, action) => {
  const {payload} = action;
  return {
    ...state,
    topMenuButtons: [
      ...payload,
    ]
  };
}

export const setLoadingHandler = (state, action) => {
  const {payload} = action;
  return {
    ...state,
    loading: payload
  };
}

export default typeToReducer({
  [mainInit]: state => ({
    ...state,
    topMenuButtons: [],
    loading: false
  }),
  [saveButtons]: saveButtonsHandler,
  [setLoading]: setLoadingHandler
}, initialState);
