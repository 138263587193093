import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Post.style';

import {
    actions as MainContainerActions,
} from '../../redux/reducers/MainContainer/MainContainer.reducer'

import Button from '../Button/Button.component';
import PostComments from '../PostComments/PostComments.component';

class Post extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showComments: false,
        }

    }

    componentDidMount() {
    }

    _showComments = (postId) => {
        this.setState(prevState => ({
            showComments: !prevState.showComments
        }));

    }

    _deletePost = () => {

        const {postData, onDeletePost} = this.props;

        onDeletePost(postData.id);
    }

    render() {
        const {postData} = this.props;
        return (
            <div
                style={styles.container}
            >
                <div style={styles.postDetail}>
                    <h3 style={styles.title}>{postData.title}</h3>
                    <div style={styles.userDetailDesc}>
                        <p style={styles.p}> {postData.body} </p>
                    </div>
                    <button onClick={() => this._showComments(postData.id)}> {(this.state.showComments) ? 'Hide Comments' : 'Show Comments'} </button>
                    {
                        this.state.showComments
                        && <PostComments postId={postData.id} />
                    }
                </div>
                <div style={styles.linksContainer}>
                    <Button
                        title='Edit'
                        onPress={() => console.log('test')}
                        disabled={false}
                        iconName='icon_edit'
                        buttonType='plain'
                        buttonSize='small'
                        customStyle={styles.linkButton}
                        customImageStyle={styles.image}
                    ></Button>
                    <Button
                        title='Delete'
                        onPress={this._deletePost}
                        disabled={false}
                        iconName='icon_delete'
                        buttonType='secondaryNegative'
                        buttonSize='small'
                        customStyle={styles.linkButton}
                        customImageStyle={styles.image}
                    ></Button>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    value: []
});

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(MainContainerActions.setLoading(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Post);
