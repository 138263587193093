import React, { Component }  from 'react';
import { BrowserRouter as Router,
    Switch,
    Route
   } from 'react-router-dom'
import styles from './ContentWrapper.style';
import UsersContainer from '../Users/Users.container';
import UserPosts from '../UserPosts/UserPosts.container';
import UserAlbums from '../UserAlbums/UserAlbums.container';
import UserPhotos from '../UserPhotos/UserPhotos.container';
import PhotoDetail from '../PhotoDetail/PhotoDetail.container';
import FirstPage from '../FirstPage/FirstPage.screen';

const routes = [
    {
      path: "/",
      exact: true,
      main: () => <FirstPage  />
    },
    {
      path: "/users",
      exact: true,
      main: () => <UsersContainer  />
    },
    {
      path: "/posts/:id",
      exact: true,
      main: () => <UserPosts />
    },
    {
      path: "/albums/:id",
      exact: true,
      main: () => <UserAlbums />
    },
    {
      path: "/photos/:id/:userId",
      exact: true,
      main: () => <UserPhotos />
    },
    {
      path: "/photodetail/:id/:userId",
      exact: true,
      main: () => <PhotoDetail />
    }
  ];

export class ContentWrapper extends Component {

  render() {
    return (
      <Router>
        <div style={styles.app}>
          <Switch>
            {routes.map((route, index) => (
              <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.main />}
              />
            ))}
          </Switch>
        </div>
      </Router>
    );
  }
}
    
export default ContentWrapper;
      