import { createAction } from 'redux-actions';
import typeToReducer from 'type-to-reducer';

// ------------------------------------
// Actions
// ------------------------------------

const userPostInit = createAction('USERPOST_INIT');
const saveUserPosts = createAction('SAVE_USER_POSTS');
const setUserDetail = createAction('SET_USER_DETAIL');

export const actions = {
    userPostInit,
    saveUserPosts,
    setUserDetail
};

// ------------------------------------
// Reducer
// ------------------------------------

export const initialState = {
  userPosts: [],
  userDetail: {}
};

export const saveUserPostsHandler = (state, action) => {
    const { payload } = action;
    return {
      ...state,
      userPosts: [
        ...payload,
      ]
    };
}
export const setUserDetailHandler = (state, action) => {
    const { payload } = action;
    return {
      ...state,
      userDetail: {
        ...payload,
      }
    };
}

export default typeToReducer({
  [userPostInit]: state => ({
    ...state,
    userPosts: [],
    userDetail: {}
  }),
  [saveUserPosts]: saveUserPostsHandler,
  [setUserDetail]: setUserDetailHandler
}, initialState);
