import React, {Component} from 'react';
import styles from './PostComments.style';
import {connect} from 'react-redux';
import Button from '../Button/Button.component';
import TextForm from '../TextForm/TextForm.component';

import {
    actions as MainContainerActions,
} from '../../redux/reducers/MainContainer/MainContainer.reducer'
import {getPostComments, deleteComment, updateComments} from '../../apis/UserPosts.spi';


class PostComments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comments: []
        };
    }

    componentDidMount() {
        this._getPostComments(this.props.postId);
    }

    _onEditCommentSubmited = (data) => {
        this._updateComments(data)
    }

    _getCommentsCallback = (data) => {
        this.props.setLoading(false);
        data && data.map((comment) => {
            return comment.editMode = false;

        })

        this.setState({comments: data});
    }

    _getPostComments = async (postId) => {
        this.props.setLoading(true);
        await getPostComments({postId}, this._getCommentsCallback)

    }

    _updateCommentsState = (newCommentsData) => {
        this.props.setLoading(false);
        this.setState({comments: newCommentsData});
    }

    _deleteComment = (commentId) => {
        this.props.setLoading(true);
        deleteComment({commentId: commentId}, this._removeComment)
            .then(this._updateCommentsState);
    }

    _removeComment = (commentId) => {
        let currentComments = this.state.comments;
        const idx = currentComments.findIndex((comment) => comment.id === commentId)
        delete currentComments[idx];

        return currentComments.filter(function (el) {
            return el != null;
        });;
    }


    _updateCommentsCallback = (commentData) => {
        this.props.setLoading(false);
        let currentComments = this.state.comments;
        const idx = currentComments.findIndex((comment) => comment.id === commentData.id)
        currentComments[idx] = commentData

        return currentComments.filter(function (el) {
            return el != null;
        });;
    }

    _updateComments(editedData) {
        this.props.setLoading(true);
        updateComments({commentData: editedData}, this._updateCommentsCallback)
            .then(newComments => this._updateCommentsState(newComments))
            .then(() => this._editComments(editedData.id))
    }

    _renderTextForm(comment) {

        return (<TextForm {...comment} onSubmit={this._onEditCommentSubmited} />)
    }

    _editComments = (commentId) => {
        this.setState(prevState => {
            let newData = [...prevState.comments]

            newData.map((comment) => {
                return comment.editMode = (comment.id === commentId) ? !comment.editMode : comment.editMode
            });

            return newData
        });
    }


    render() {
        const {comments} = this.state;

        return (
            <div
                style={styles.container}
            >
                {
                    comments && comments.map((comment) => (
                        <div style={styles.comments} key={comment.postId + '-' + comment.id}>
                            <h5 style={styles.title}>{comment.name}</h5>
                            <div > {comment.email} </div>

                            {(comment.editMode === true) ? this._renderTextForm(comment) : <p> {comment.body} </p>}

                            <div style={styles.linksContainer}>
                                <Button
                                    title='Edit'
                                    onPress={() => this._editComments(comment.id)}
                                    disabled={false}
                                    iconName='icon_edit'
                                    buttonType='plain'
                                    buttonSize='small'
                                    customStyle={styles.linkButton}
                                    customImageStyle={styles.image}
                                ></Button>
                                <Button
                                    title='Delete'
                                    onPress={() => this._deleteComment(comment.id)}
                                    disabled={false}
                                    iconName='icon_delete'
                                    buttonType='secondaryNegative'
                                    buttonSize='small'
                                    customStyle={styles.linkButton}
                                    customImageStyle={styles.image}
                                ></Button>
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
}


const mapStateToProps = state => ({
    value: []
});

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(MainContainerActions.setLoading(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostComments);
