import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.style';
import { ParallaxProvider } from 'react-scroll-parallax';

// import AppHeader from '../components/Headers/Header.component';
import ContentWrapper from './ContentWrapper/ContentWrapper.container';
import {
  actions as MainContainerActions,
} from '../redux/reducers/MainContainer/MainContainer.reducer'

import { getTopMenu } from '../apis/TopMenu.api';


export class MainContainer extends Component {

  componentDidMount() {
    this._getTopMenu();
  }

  _getMenuCallback = (data) => {
    this.props.saveButtons(data);
  }

  _getTopMenu = () => {
    getTopMenu([], this._getMenuCallback)
  }

  render() {
    return (
      <div style={styles.app}>
        <ParallaxProvider >
          {/* <AppHeader /> */}
          <ContentWrapper />
        </ParallaxProvider>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.MainReducer.loading
});

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = dispatch => ({
  saveButtons: (data) => dispatch(MainContainerActions.saveButtons(data)),
  setLoading: (data) => dispatch(MainContainerActions.setLoading(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);