import { createAction } from 'redux-actions';
import typeToReducer from 'type-to-reducer';

// ------------------------------------
// Actions
// ------------------------------------

const userPhotoDetailInit = createAction('USERPHOTO_DETAIL_INIT');
const saveUserPhotoDetails = createAction('SAVE_USER_PHOTO_DETAILS');
const setUserDetail = createAction('SET_USER_DETAIL');

export const actions = {
    userPhotoDetailInit,
    saveUserPhotoDetails,
    setUserDetail
};

// ------------------------------------
// Reducer
// ------------------------------------

export const initialState = {
  userPhotoDetails: [],
  userDetail: {}
};

export const saveUserPhotoDetailsHandler = (state, action) => {
    const { payload } = action;
    return {
      ...state,
      userPhotoDetails: {
        ...payload,
      }
    };
}
export const setUserDetailHandler = (state, action) => {
    const { payload } = action;
    return {
      ...state,
      userDetail: {
        ...payload,
      }
    };
}

export default typeToReducer({
  [userPhotoDetailInit]: state => ({
    ...state,
    userPhotoDetails: [],
    userDetail: {}
  }),
  [saveUserPhotoDetails]: saveUserPhotoDetailsHandler,
  [setUserDetail]: setUserDetailHandler
}, initialState);
