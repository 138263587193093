import HomeReducer from './Home/home.reducer';
import MainReducer from './MainContainer/MainContainer.reducer';
import UsersReducer from './UsersContainer/UsersContainer.reducer';
import UserPosts from './UserPosts/UserPosts.reducer';
import UserAlbums from './UserAlbums/UserAlbums.reducer';
import UserPhotos from './UserPhotos/UserPhotos.reducer';
import PhotoDetail from './PhotoDetail/PhotoDetail.reducer';


export default {
    HomeReducer,
    MainReducer,
    UsersReducer,
    UserPosts,
    UserAlbums,
    UserPhotos,
    PhotoDetail
}