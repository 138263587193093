import { createAction } from 'redux-actions';
import typeToReducer from 'type-to-reducer';

// ------------------------------------
// Actions
// ------------------------------------

const homeinit = createAction('HOMEINIT');

export const actions = {
  homeinit
};

// ------------------------------------
// Reducer
// ------------------------------------

export const initialState = {
  rehydrated: false
};

export default typeToReducer({
  [homeinit]: state => ({
    ...state,
    rehydrated: true
  })
}, initialState);
