import { Fonts, Colors } from '../../utils/Themes';

export default {
    avatarContainer: {
        width: '30%'
    },
    userDetailContainer: {
        width: '70%',
        textAlign: 'left',
        paddingLeft: '10px'
    },
    userDetailDesc: {
        display: 'flex',
    },
    linksContainer: {
        width: '30%'
    },
    userAvatar: {
        maxHeight: '100px'
    },
    title: {
        marginBlockStart: '10px',
        marginBlockEnd: '5px'
    },
    userDetailText: {
        fontSize: Fonts.size.small,
        margin: '5px',
        width: '50%'
    },
    catchPhraseText: {
        fontStyle: 'italic'
    },
    h4: {
        marginBlockStart: '0px',
        marginBlockEnd: '3px'
    },
    userPanelContainer: {
        padding: '10px 15px',
        margin: '5px',
        backgroundColor: Colors.fadeGrey,
        border: '1px solid ' + Colors.warmGrey,
        borderRadius: '3px',
        display: 'flex'
    },
    linkButton: {
        height: '100px',

    }
}