
/* eslint-disable global-require */
export const images = {
  icon_album: require('../../assets/img/albumicon.png'),
  icon_post: require('../../assets/img/postsicon.png'),
  icon_edit: require('../../assets/img/edit_icon.png'),
  icon_delete: require('../../assets/img/delete_icon.png'),
  user_ava: require('../../assets/img/avatardefault.png'),
  bg2: require('../../assets/img/bg2.jpg'),
  bg3: require('../../assets/img/bg3.jpg'),
  ard: require('../../assets/img/ard.png'),
};
export default images;
/* eslint-enable global-require */
