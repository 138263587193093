import { Colors } from '../../utils/Themes';

export default {
    container: {
        height: '300px',
        padding: '10px 15px',
        margin: '5px',
        backgroundColor: Colors.fadeGrey,
        border: '1px solid ' + Colors.warmGrey,
        borderRadius: '3px',
        display: 'flex'
    },
    tumbnailDetail: {
        width: '100%',
        textAlign: 'center'
    },
    userDetailDesc: {
        display: 'flex',
    },
    images: {
        height: '80%'
    },
    linksContainer: {
        width: '20%'
    },
    title: {
        marginBlockStart: '10px',
        marginBlockEnd: '5px'
    },
    linkButton: {
        height: '40px',
        border: Colors.transparent,
    },
    image: {
        height: '60%'
    }
}