import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
import styles from './UserAlbums.styles';

import {
    actions as UserAlbumsActions,
} from '../../redux/reducers/UserAlbums/UserAlbums.reducer';
import {
    actions as MainContainerActions,
} from '../../redux/reducers/MainContainer/MainContainer.reducer'
import {getUserAlbums, getUserDetail} from '../../apis/UserAlbums.api';

import Tumbnail from '../../components/Tumbnail/Tumbnail.component';
import UserProfile from '../../components/UserProfile/UserProfile.component';

export class UserAlbumsContainer extends Component {

    componentDidMount() {
        let id = (this.props.match.params.id) ? this.props.match.params.id : 1;
        this._getUserAlbums(id);
        this._getUserDetail(id)
    }

    _getUserAlbumsCallback = (data) => {
        this.props.setLoading(false);
        this.props.saveUserAlbums(data);
    }

    _getUserDetailCallback = (data) => {
        this.props.setLoading(false);
        this.props.setUserDetail(data);
    }

    _getUserAlbums = async (userID) => {

        this.props.setLoading(true);
        await getUserAlbums({userID: userID}, this._getUserAlbumsCallback)
    }

    _getUserDetail = async (userID) => {

        this.props.setLoading(true);
        await getUserDetail({userID: userID}, this._getUserDetailCallback)
    }

    render() {
        const {userAlbums, userDetail} = this.props;
        return (
            <div style={styles.app}>
                <div style={styles.userProfile} >
                    <UserProfile userData={userDetail} />
                </div>
                <div style={styles.albumList} >
                    {userAlbums.map((userAlbum) => {
                        // return <div key={userAlbum.id}>{ userAlbum.title }</div>
                        return <Link to={'/photos/' + userAlbum.id + '/' + userAlbum.userId} style={styles.albumTumbnail} key={userAlbum.userId + userAlbum.id} ><Tumbnail tumbnailData={userAlbum} /> </Link>
                    })}
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    userAlbums: state.UserAlbums.userAlbums,
    userDetail: state.UserAlbums.userDetail
});

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = dispatch => ({
    saveUserAlbums: (data) => dispatch(UserAlbumsActions.saveUserAlbums(data)),
    setUserDetail: (data) => dispatch(UserAlbumsActions.setUserDetail(data)),
    setLoading: (data) => dispatch(MainContainerActions.setLoading(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserAlbumsContainer));