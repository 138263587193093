const colors = {  
    nafiyaPink: '#ff7c80',
    nafiyaLightPink: '#fecfcf',
    nafiyaRed: '#f04138',
    nafiyaDarkRed: '#690202',
    gainsboro: '#d8d8d8',
    darkCloud: '#ededed',
    orange: '#fdbb11',
    dustyOrange: '#f78d2c',
    grey: '#767676',
    turquoise: '#00b4c5',
    darkBlue: '#0090c3',
    warmGrey: '#878787',
    warmGreyOpacity3: ('rgba(125, 125, 125, 0.3)'),
    white: '#ffffff',
    lightGrey: '#dfdfdf',
    fadeGrey: '#f7f7f7',
    green: '#88c65e',
    lightOliveGreenAlpha: 'rgba(143, 188, 90, 0.25)',
    lightShadow: 'rgba(0, 0, 0, 0.15)',
    lightSilver: '#d7d7d7',
    paleRed: '#d82f27',
    freeSpeechRed: '#ce0000',
    yellow: '#f8e71c',
    whiteOpacity8: 'rgba(255, 255, 255, 0.8)',
    whiteOpacity7: 'rgba(255, 255, 255, 0.7)',
    whiteOpacity6: 'rgba(255, 255, 255, 0.6)',
    whiteOpacity5: 'rgba(255, 255, 255, 0.5)',
    whiteOpacity3: 'rgba(255, 255, 255, 0.3)',
    whiteOpacity2: 'rgba(255, 255, 255, 0.2)',
    whiteOpacity1: 'rgba(255, 255, 255, 0.1)',
    transparent: 'rgba(0, 0, 0, 0)',
    overlay: 'rgba(0, 0, 0, 0.6)',
    darkGreen: '#31814c',
    calmGreen: 'rgb(0,161,176)',
    lightTurquoise: 'rgba(0, 180, 197, 0.3)',
    darkGrey: '#bdbdbd',
    lightCyan: '#f4fbfd',
    lightBlue: '#00a3dc',
    overOrange: '#ffdcc7',
    overBlue: '#d2f4ff',
    overYellow: '#ffecc4',
    sliderGray: '#d9d9d9',
    indigo: '#435FC2',
  
    greenLightPoint: '#8ebd54',
    greenDarkAtm: '#59c420',
    orangeBranch: '#ff9739',
  
    dreamSaverPurple: '#712d83',
    dreamSaverLightPurple: '#703ba6',
    dreamSaverOrange: '#ee8e26',
    dreamSaverPurple2: '#592F84',
    gaugeProgressBg: '#CDB9E0',
  
    formPickerToolBarBg: [236, 236, 236, 1],
    formPickerConfirmButton: [0, 122, 255, 1],
    formPickerBg: [255, 255, 255, 1],
    formPickerText: [0, 0, 0, 1],
  
    gaugeProgressMaxiSaver: '#1c6c37'
  
    // ---- end of other default colors ---- //
  
  };
  
  export default colors;
  