export default {
    app: {
        textAlign: 'center',
        maxWidth: '80%',
        margin: '10px auto',
        display: 'flex'
    },
    albumList: {
        width: '70%',
        flexFlow: 'wrap',
        display: 'flex'
    },
    userProfile: {
        width: '30%'
    },
    albumTumbnail: {
        width: '50%',
        maxWidth: '50%',
        flexBasis: '50%'
    }
}