import { createAction } from 'redux-actions';
import typeToReducer from 'type-to-reducer';

// ------------------------------------
// Actions
// ------------------------------------

const userPhotoInit = createAction('USERPHOTO_INIT');
const saveUserPhotos = createAction('SAVE_USER_PHOTOS');
const setUserDetail = createAction('SET_USER_DETAIL');

export const actions = {
    userPhotoInit,
    saveUserPhotos,
    setUserDetail
};

// ------------------------------------
// Reducer
// ------------------------------------

export const initialState = {
  userPhotos: [],
  userDetail: {}
};

export const saveUserPhotosHandler = (state, action) => {
    const { payload } = action;
    return {
      ...state,
      userPhotos: [
        ...payload,
      ]
    };
}
export const setUserDetailHandler = (state, action) => {
    const { payload } = action;
    return {
      ...state,
      userDetail: {
        ...payload,
      }
    };
}

export default typeToReducer({
  [userPhotoInit]: state => ({
    ...state,
    userPhotos: [],
    userDetail: {}
  }),
  [saveUserPhotos]: saveUserPhotosHandler,
  [setUserDetail]: setUserDetailHandler
}, initialState);
