import { createAction } from 'redux-actions';
import typeToReducer from 'type-to-reducer';

// ------------------------------------
// Actions
// ------------------------------------

const usersnit = createAction('USERS_INIT');
const saveUsers = createAction('SAVE_USERS');

export const actions = {
    usersnit,
    saveUsers
};

// ------------------------------------
// Reducer
// ------------------------------------

export const initialState = {
  users: []
};

export const saveUsersHandler = (state, action) => {
    const { payload } = action;
    return {
      ...state,
      users: [
        ...payload,
      ]
    };
}

export default typeToReducer({
  [usersnit]: state => ({
    ...state,
    users: []
  }),
  [saveUsers]: saveUsersHandler
}, initialState);
