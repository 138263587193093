import React  from 'react';
import styles from './Button.style';
import {Images} from '../../utils/Themes';

const Button = (props) => {
    const { title, onPress, disabled, buttonType, buttonSize, iconName, customStyle, customImageStyle} = props;

    return (
        <button
            onClick={disabled ? undefined : onPress}
            style={{...styles.container(buttonType, buttonSize),  ...customStyle}} 
        >
            {iconName && <img src={Images[iconName]} alt={title} style={{ ...styles.images, ...customImageStyle}}></img> }
            <span style={styles.text(buttonType, buttonSize)}>
                {title !== '' && title}
            </span>
        </button>
    );
}
      
export default Button;
