import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

export default (rootReducer) => {
    const middleware = [
        thunk
    ]
    
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

    return store;
}
