import React, {Component} from 'react';
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Images } from '../../utils/Themes';
import './FirstPage.style.scss';

export class FirstPage extends Component {
render() {
  return (
    <div className={'main-container'}>
      <div className={'container'}>
        <ParallaxBanner
          className="page-one"
          layers={[
              {
                  image: Images.bg2,
                  amount: 0.3,
              },
          ]}
          style={{
              height: '100vh',
          }}
        >
          <h1 id="test" className="greeting" >Hi, I'm Andang !</h1>
          <h3 className="greeting" >nice to meet you,</h3>

          <section id="section05" className="demo">
            <h1>More info about me..?</h1>
            <a href="#section06"><span></span></a>
          </section>
        </ParallaxBanner>
        <ParallaxBanner
          className="second-page"
          layers={[
              {
                  image: Images.bg3,
                  amount: 0.3,
              }
          ]}
          style={{
              minHeight: '60vh'
          }}
        >
          <div className="detail-container" >
            <div className="detail-header" >
              <h1 >Andang Rian Dimas </h1>
              <h3 >Software Engineer</h3>
            </div> 
            <div className="detail-about-me" >
              <h2 >""</h2>
              <h3 >I've been working in software engineering since 2014. I initially worked in Jakarta for about 2 years before I decided to move to Yogyakarta back in September 2016. <br />
                Since then, I worked with various people around the world and working remotely  from this lovely city.
              </h3>
            </div>  
          </div>
        </ParallaxBanner>
        <Parallax
          offsetYMin={-50}
          offsetYMax={50}
          className="circle"
        >
          <div className="footer-container" >
            <div className="footer-header" >
              <h3 >Get in touch with me...</h3>
            </div> 
            <div className="footer-connections" >
              <div className="columns" >
                <div className="socmed-column" >
                  <div className="socmed-list" onClick={()=> window.open("https://www.instagram.com/andangrdimas/", "_blank")}>
                    <div className="icon-tubmnail">
                      <FontAwesomeIcon className='soscmed-icon' icon={faInstagram} size="3x"/>
                    </div>
                    <div className="icon-desc">
                      <strong>Instagram,</strong> <br /> I love taking picture, let's connect on instagram
                    </div>
                  </div>
                </div>  
                <div className="socmed-column" >
                  <div className="socmed-list" onClick={()=> window.open("https://www.linkedin.com/in/andang-rian-dimas/", "_blank")}>
                    <div className="icon-tubmnail">
                      <FontAwesomeIcon className='soscmed-icon' icon={faLinkedin} size="3x"/> 
                    </div>
                    <div className="icon-desc">
                      <strong>LinkedIn,</strong> <br /> I shared my recent professional activities here
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns" >
                <div className="socmed-list">
                  <div className="icon-tubmnail">
                    <FontAwesomeIcon className='soscmed-icon' icon={faEnvelopeOpenText}  size="3x"/> 
                  </div>
                  <div className="icon-desc">
                    <strong>Mail,</strong> <br /> Feel free to message me through <u> dms2707@gmail.com </u>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
        <p className="footter-copyright">Copyright 2020 by <img className="ard-img" src={Images.ard}></img> andang r dimas</p>
   </div>
  );
}
}

export default FirstPage;