import { Metrics, Colors, Fonts } from '../../utils/Themes';

const sizeOptions = {
  fullWidth: {
    padding: 50,
    height: 54,
    icon: Metrics.icons.small
  },
  large: {
    padding: 50,
    height: 54,
    icon: Metrics.icons.small
  },
  medium: {
    padding: 25,
    height: 45,
    icon: Metrics.icons.small
  },
  small: {
    padding: 16.5,
    height: 35,
    icon: Metrics.icons.tiny
  }
};

const colorOptions = {
  primary: {
    background: Colors.darkGreen,
    text: Colors.white,
    border: Colors.darkGreen,
    hoverBackground: Colors.darkGreen,
    hoverText: Colors.white,
    hoverBorder: Colors.darkGreen
  },
  secondary: {
    background: Colors.calmGreen,
    text: Colors.white,
    border: Colors.calmGreen,
    hoverBackground: Colors.transparent,
    hoverText: Colors.darkGreen,
    hoverBorder: Colors.darkGreen
  },
  secondaryNegative: {
    background: Colors.transparent,
    text: Colors.nafiyaDarkRed,
    border: Colors.nafiyaDarkRed,
    hoverBackground: Colors.transparent,
    hoverText: Colors.nafiyaDarkRed,
    hoverBorder: Colors.nafiyaDarkRed
  },
  plain: {
    background: Colors.transparent,
    text: Colors.dustyOrange,
    border: Colors.transparent,
    hoverBackground: Colors.transparent,
    hoverText: Colors.darkGreen,
    hoverBorder: Colors.transparent
  }
};


export default {
    button: {
        outline: 'none'
    },
    buttonNoStretch: {
      alignItems: 'flex-start'
    },
    container: (kind, buttonSize) => ({
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 2,
      margin: 2,
      height: sizeOptions[buttonSize].height,
      paddingLeft: sizeOptions[buttonSize].padding,
      paddingRight: sizeOptions[buttonSize].padding,
      borderWidth: kind === 'plain' ? 0 : 1,
      borderColor: colorOptions[kind].border,
      backgroundColor: colorOptions[kind].background
    }),
    disabledButton: {
      opacity: 0.5
    },
    conMarginLeft: buttonSize => ({
        marginLeft: buttonSize === 'small' ? Metrics.smallMargin : Metrics.baseMargin
    }),
    iconMarginRight: buttonSize => ({
        marginRight: buttonSize === 'small' ? Metrics.smallMargin : Metrics.baseMargin
    }),
    text: (kind, buttonSize, customColor) => ({
        fontWeight: buttonSize === 'large' ? 'bolder' : 'bold',
        fontSize: buttonSize === 'small' ? Fonts.size.regular : Fonts.size.medium,
        color: (kind !== 'primary' && customColor) || colorOptions[kind].text
    }),
    images: {
        maxHeight: '80px',
        margin: 'auto',
        display: 'flex'
    }
  };
  