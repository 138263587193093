const type = {
    light: 'Roboto-Light',
    base: 'Roboto-Regular',
    medium: 'Roboto-Medium',
    bold: 'Roboto-Bold',
    emphasis: 'Roboto-Italic'
  };
  
  const size = {
    h1: 38,
    h2: 34,
    h3: 30,
    h4: 26,
    h5: 20,
    h6: 19,
    h1_extra: 40,
    extraLarge: 28,
    large: 24,
    largeInput: 21,
    input: 18,
    medium: 16,
    regular_medium: 15,
    regular: 14,
    small_regular: 13,
    small: 12,
    smaller: 11,
    small_extra: 10,
    tiny: 8.5
  };
  
  const style = {
    h1: {
      fontFamily: type.base,
      fontSize: size.h1
    },
    h2: {
      fontWeight: 'bold',
      fontSize: size.h2
    },
    h3: {
      fontFamily: type.emphasis,
      fontSize: size.h3
    },
    h4: {
      fontFamily: type.base,
      fontSize: size.h4
    },
    h5: {
      fontFamily: type.base,
      fontSize: size.h5
    },
    h6: {
      fontFamily: type.emphasis,
      fontSize: size.h6
    },
    light: {
      fontFamily: type.light,
      fontSize: size.regular
    },
    normal: {
      fontFamily: type.base,
      fontSize: size.regular
    },
    description: {
      fontFamily: type.base,
      fontSize: size.medium
    },
    button: {
      fontFamily: type.medium,
      fontSize: size.regular
    },
    notificationTitle: {
      fontFamily: type.light,
      fontSize: 22
    }
  };
  
  export default {
    type,
    size,
    style
  };
  