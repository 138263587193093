import React  from 'react';
import styles from './UserProfile.style';

import { Images } from '../../utils/Themes/';

const UserProfile = (props) => {
    const { userData } = props;

    return (
        <div
            style={styles.userPanelContainer} 
        >
            <div style={styles.avatarContainer}>
                <img src={Images.user_ava} alt={userData.name} style={styles.userAvatar}></img>
            </div>
            <div style={styles.userDetailContainer}>
                <h3 style={styles.title}>{userData.name}</h3>
                <div style={styles.userDetailText}>
                    <div >{userData.email}</div>
                    <div >@{userData.username}</div>
                    <a href={userData.website}>{userData.website}</a>
                </div>
                    
                
                
            </div>
        </div>
    );
}
      
export default UserProfile
