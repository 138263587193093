import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styles from './PhotoDetail.styles';

import {
    actions as PhotoDetailActions,
} from '../../redux/reducers/PhotoDetail/PhotoDetail.reducer';
import {
    actions as MainContainerActions,
} from '../../redux/reducers/MainContainer/MainContainer.reducer'
import {getPhotoDetail, getUserDetail} from '../../apis/PhotoDetail.api';

import Tumbnail from '../../components/Tumbnail/Tumbnail.component';
import UserProfile from '../../components/UserProfile/UserProfile.component';

export class PhotoDetailContainer extends Component {

    componentDidMount() {

        let id = (this.props.match.params.id) ? this.props.match.params.id : 1;
        let userId = (this.props.match.params.userId) ? this.props.match.params.userId : 1;
        this._getPhotoDetail(id);
        this._getUserDetail(userId)
    }

    _getPhotoDetailCallback = (data) => {
        this.props.setLoading(false);
        this.props.savePhotoDetail(data);
    }

    _getUserDetailCallback = (data) => {
        this.props.setLoading(false);
        this.props.setUserDetail(data);
    }

    _getPhotoDetail = async (photoId) => {

        this.props.setLoading(true);
        await getPhotoDetail({photoId: photoId}, this._getPhotoDetailCallback)
    }

    _getUserDetail = async (userID) => {

        this.props.setLoading(true);
        await getUserDetail({userID: userID}, this._getUserDetailCallback)
    }

    render() {
        const {photoData, userDetail} = this.props;
        return (
            <div style={styles.app}>
                <div style={styles.userProfile} >
                    <UserProfile userData={userDetail} />
                </div>
                <div style={styles.PhotoDetail} >
                    <Tumbnail useURL={true} tumbnailData={photoData} customContainerStyles={styles.customContainerStyles}></Tumbnail>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    photoData: state.PhotoDetail.userPhotoDetails,
    userDetail: state.PhotoDetail.userDetail
});

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = dispatch => ({
    savePhotoDetail: (data) => dispatch(PhotoDetailActions.saveUserPhotoDetails(data)),
    setUserDetail: (data) => dispatch(PhotoDetailActions.setUserDetail(data)),
    setLoading: (data) => dispatch(MainContainerActions.setLoading(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PhotoDetailContainer));