import React  from 'react';
import styles from './UserProfileRow.style';
import { Redirect, Link } from 'react-router-dom';

import { Images } from '../../utils/Themes/';
import Button from '../Button/Button.component';



const UserProfileRow = (props) => {
    const { userData } = props;

    let test = false;

    if (test) {
        return <Redirect push to="/sample" />;
      }

    return (
        <div
            style={styles.userPanelContainer} 
        >
            <div style={styles.avatarContainer}>
                <img src={Images.user_ava} alt={userData.name} style={styles.userAvatar}></img>
            </div>
            <div style={styles.userDetailContainer}>
                <h3 style={styles.title}>{userData.name}</h3>
                <div style={styles.userDetailDesc}>
                    <div style={styles.userDetailText}>
                        <div >{userData.email}</div>
                        <div >@{userData.username}</div>
                        <a href={userData.website}>{userData.website}</a>
                    </div>
                    <div style={styles.userDetailText}>
                        <h4 style={styles.h4}>Company Profile:</h4>
                        <div >{userData.company.name}</div>
                        <div style={styles.catchPhraseText}>"{userData.company.catchPhrase}"</div>
                    </div>
                </div>
                
                
            </div>
            <div style={styles.linksContainer}>
                <Link to={'/posts/'+ userData.id } >
                    <Button 
                        title='View Posts' 
                        disabled={false}
                        iconName='icon_post'
                        buttonType='plain' 
                        buttonSize='small'
                        customStyle={styles.linkButton}
                    ></Button>
                </Link>

                <Link to={'/albums/'+ userData.id } >
                <Button 
                    title='View Albums' 
                    onPress={() => console.log('test')} 
                    disabled={false}
                    iconName='icon_album'
                    buttonType='plain' 
                    buttonSize='small'
                    customStyle={styles.linkButton}
                ></Button>
                </Link>
            </div>
        </div>
    );
}
      
export default UserProfileRow;
