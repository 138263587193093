import React  from 'react';
import styles from './Tumbnail.style';
import { Images } from '../../utils/Themes';

const Tumbnail = (props) => {
    const { tumbnailData, useURL, customContainerStyles, customImageStyle, customTitleStyles} = props;
    let imgSrc = (useURL && tumbnailData) ? tumbnailData.url : tumbnailData.thumbnailUrl;
    imgSrc = (imgSrc) ? imgSrc : Images['icon_album'];

    return (
        <div
            style={{ ...styles.container, ...customContainerStyles}} 
        >
            <div style={{...styles.tumbnailDetail}}>
                <img src={imgSrc} alt={tumbnailData.title} style={{...styles.images, ...customImageStyle}}></img>
                <h3 style={{...styles.title, ...customTitleStyles}}>{tumbnailData.title}</h3>
            </div>
        </div>
    );
}
    
export default Tumbnail;
