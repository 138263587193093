import { Fonts } from '../utils/Themes';

export default {
    app: {
        textAlign: 'center',
        height: '100%'
    },
    appIntro: {
        fontSize: Fonts.size.large
    }
}