const getUsers = (payload, callback) => {

    return fetch('https://jsonplaceholder.typicode.com/users', {
        crossDomain: true,
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    })
        .then(response => response.json())
        .then(json => callback(json))

}

export {
    getUsers,
}