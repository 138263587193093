export default {
    app: {
        textAlign: 'center',
        maxWidth: '80%',
        margin: '10px auto',
        display: 'flex'
    },
    postList: {
        width: '70%',
    },
    userProfile: {
        width: '30%'
    }
}