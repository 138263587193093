import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
import styles from './UserPhotos.styles';

import {
    actions as UserPhotosActions,
} from '../../redux/reducers/UserPhotos/UserPhotos.reducer';
import {
    actions as MainContainerActions,
} from '../../redux/reducers/MainContainer/MainContainer.reducer'
import {getUserPhotos, getUserDetail} from '../../apis/UserPhotos.api';

import Tumbnail from '../../components/Tumbnail/Tumbnail.component';
import UserProfile from '../../components/UserProfile/UserProfile.component';

export class UserPhotosContainer extends Component {
    
    componentDidMount() {

        let id = (this.props.match.params.id) ? this.props.match.params.id : 1;
        let userId = (this.props.match.params.userId) ? this.props.match.params.userId : 1;
        this._getUserPhotos(id);
        this._getUserDetail(userId)
    }

    _getUserPhotosCallback = (data) => {
        this.props.setLoading(false);
        this.props.saveUserPhotos(data);
    }

    _getUserDetailCallback = (data) => {
        this.props.setLoading(false);
        this.props.setUserDetail(data);
    }

    _getUserPhotos = async (albumID) => {
        this.props.setLoading(true);
        await getUserPhotos({albumId: albumID}, this._getUserPhotosCallback)
    }

    _getUserDetail = async (userId) => {
        this.props.setLoading(true);

        await getUserDetail({userID: userId}, this._getUserDetailCallback)
    }

    render() {
        const {userPhotos, userDetail} = this.props;
        return (
            <div style={styles.app}>
                <div style={styles.userProfile} >
                    <UserProfile userData={userDetail} />
                </div>
                <div style={styles.photoList} >
                    {userPhotos.map((userPhoto) => {
                        // return <div key={userPhoto.id}>{ userPhoto.title }</div>
                        return <Link 
                                    to={'/photodetail/' + userPhoto.id + '/' + userDetail.id } 
                                    style={styles.photoTumbnail} 
                                    key={userPhoto.albumId + userPhoto.id}
                                >
                                    <Tumbnail tumbnailData={userPhoto} />
                                </Link>
                    })}
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    userPhotos: state.UserPhotos.userPhotos,
    userDetail: state.UserPhotos.userDetail
});

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = dispatch => ({
    saveUserPhotos: (data) => dispatch(UserPhotosActions.saveUserPhotos(data)),
    setUserDetail: (data) => dispatch(UserPhotosActions.setUserDetail(data)),
    setLoading: (data) => dispatch(MainContainerActions.setLoading(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPhotosContainer));