import { Colors } from '../../utils/Themes';

export default {
    container: {
        minHeight: '100px',
        padding: '10px 15px',
        margin: '5px',
        backgroundColor: Colors.fadeGrey,
        border: '1px solid ' + Colors.warmGrey,
        borderRadius: '3px',
        display: 'flex'
    },
    postDetail: {
        width: '80%',
        textAlign: 'left'
    },
    userDetailDesc: {
        display: 'flex',
        marginBottom: '5px'
    },
    p: {
        marginBlockStart: '2px',
        marginBlockEnd: '2px'
    },
    linksContainer: {
        width: '20%'
    },
    title: {
        marginBlockStart: '10px',
        marginBlockEnd: '5px'
    },
    linkButton: {
        height: '40px',
        border: Colors.transparent,
    },
    image: {
        height: '60%'
    }
}