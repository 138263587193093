import { createAction } from 'redux-actions';
import typeToReducer from 'type-to-reducer';

// ------------------------------------
// Actions
// ------------------------------------

const userAlbumInit = createAction('USERALBUM_INIT');
const saveUserAlbums = createAction('SAVE_USER_ALBUMS');
const setUserDetail = createAction('SET_USER_DETAIL');

export const actions = {
    userAlbumInit,
    saveUserAlbums,
    setUserDetail
};

// ------------------------------------
// Reducer
// ------------------------------------

export const initialState = {
  userAlbums: [],
  userDetail: {}
};

export const saveUserAlbumsHandler = (state, action) => {
    const { payload } = action;
    return {
      ...state,
      userAlbums: [
        ...payload,
      ]
    };
}
export const setUserDetailHandler = (state, action) => {
    const { payload } = action;
    return {
      ...state,
      userDetail: {
        ...payload,
      }
    };
}

export default typeToReducer({
  [userAlbumInit]: state => ({
    ...state,
    userAlbums: [],
    userDetail: {}
  }),
  [saveUserAlbums]: saveUserAlbumsHandler,
  [setUserDetail]: setUserDetailHandler
}, initialState);
