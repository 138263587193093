const getUserPosts = (payload, callback) => {
    const {userID} = payload;


    return fetch('https://jsonplaceholder.typicode.com/users/' + userID + '/posts', {
        crossDomain: true,
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    })
        .then(response => response.json())
        .then(json => callback(json))

}

const getPostComments = (payload, callback) => {
    const {postId} = payload;

    return fetch('https://jsonplaceholder.typicode.com/comments?postId=' + postId + '', {
        crossDomain: true,
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    })
        .then(response => response.json())
        .then(json => callback(json))

}

const updateComments = (payload, callback) => {
    const {commentData} = payload;

    return fetch('https://jsonplaceholder.typicode.com/comments/' + commentData.id, {
        method: 'PUT',
        body: JSON.stringify({
            ...commentData
        }),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    })
        .then(json => callback(commentData))
}

const getUserDetail = (payload, callback) => {
    const {userID} = payload;

    return fetch('https://jsonplaceholder.typicode.com/users/' + userID, {
        crossDomain: true,
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    })
        .then(response => response.json())
        .then(json => callback(json))
}

const deletePost = (payload, callback) => {
    const {postId} = payload;
    const url = 'https://jsonplaceholder.typicode.com/posts/' + postId;

    return fetch(url, {method: 'DELETE'})
        .then(() => callback(postId))
}

const deleteComment = (payload, callback) => {
    const {commentId} = payload;
    const url = 'https://jsonplaceholder.typicode.com/comments/' + commentId;

    return fetch(url, {method: 'DELETE'})
        .then(() => callback(commentId))
}

export {
    getUserPosts,
    getUserDetail,
    getPostComments,
    deletePost,
    deleteComment,
    updateComments
}