import { Fonts, Colors } from '../../utils/Themes';

export default {
    
    title: {
        marginBlockStart: '10px',
        marginBlockEnd: '5px'
    },
    comments: {
        fontSize: Fonts.size.small_extra,
        margin: '5px',
        width: '80%',
        backgroundColor:Colors.lightGrey,
        border: '1px solid ' +Colors.darkGrey
    },
    
    h5: {
        marginBlockStart: '0px',
        marginBlockEnd: '3px'
    },
    linksContainer: {
        width: '20%',
        display: 'flex'
    },
    inkButton: {
        height: '40px',
        border: Colors.transparent,
    },
    image: {
        height: '60%'
    }
}