import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styles from './Users.styles';

import {
    actions as UsersContainerActions,
} from '../../redux/reducers/UsersContainer/UsersContainer.reducer';
import {
    actions as MainContainerActions,
} from '../../redux/reducers/MainContainer/MainContainer.reducer'
import {getUsers} from '../../apis/Users.api';

import UserProfileRow from '../../components/UserProfileRow/UserProfileRow.component';

export class UsersContainer extends Component {

    componentDidMount() {
        this._getUsers();
    }

    _getUsersCallback = (data) => {
        this.props.saveUsers(data);
        this.props.setLoading(false);
    }

    _getUsers = async (page) => {
        this.props.setLoading(true);
        await getUsers([], this._getUsersCallback)
    }

    render() {
        const {users} = this.props;
        return (
            <div style={styles.app}>
                {users.map((user) => {
                    return <UserProfileRow userData={user} key={user.id + user.username} />
                })}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    users: state.UsersReducer.users
});

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = dispatch => ({
    saveUsers: (data) => dispatch(UsersContainerActions.saveUsers(data)),
    setLoading: (data) => dispatch(MainContainerActions.setLoading(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersContainer));