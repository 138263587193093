import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import styles from './UserPosts.styles';

import {
    actions as UserPostsActions,
} from '../../redux/reducers/UserPosts/UserPosts.reducer';
import {
    actions as MainContainerActions,
} from '../../redux/reducers/MainContainer/MainContainer.reducer'
import {getUserPosts, getUserDetail, deletePost} from '../../apis/UserPosts.spi';

import Post from '../../components/Post/Post.component';
import UserProfile from '../../components/UserProfile/UserProfile.component';


export class UserPostsContainer extends Component {

    componentDidMount() {

        let id = (this.props.match.params.id) ? this.props.match.params.id : 1;
        this._getUserPosts(id);
        this._getUserDetail(id)
    }

    _getUserPostsCallback = (data) => {
        this.props.setLoading(false);
        this.props.saveUserPosts(data);
    }

    _getUserDetailCallback = (data) => {
        this.props.setLoading(false);
        this.props.setUserDetail(data);
    }

    _getUserPosts = async (userID) => {
        this.props.setLoading(true);
        await getUserPosts({userID: userID}, this._getUserPostsCallback)
    }

    _getUserDetail = async (userID) => {

        this.props.setLoading(true);
        await getUserDetail({userID: userID}, this._getUserDetailCallback)
    }

    _removePost = (postId) => {
        let currentPosts = this.props.userPosts;
        const idx = currentPosts.findIndex((postData) => postData.id === postId)
        delete currentPosts[idx] ;

        return currentPosts.filter(function (el) {
            return el != null;
          });;
    }

    _deletePost = (postId) => {
        this.props.setLoading(true);
        deletePost({ postId: postId }, this._removePost)
            .then(this._getUserPostsCallback);
    }
    
    render() {
        const {userPosts, userDetail} = this.props;
        return (
            <div style={styles.app}>
                <div style={styles.userProfile} >
                    <UserProfile userData={userDetail} />
                </div>
                <div style={styles.postList} >
                    {userPosts && userPosts.map((userPost) => {
                        return <Post postData={userPost} key={userDetail.userId + '-' + userPost.id} onDeletePost={this._deletePost} />
                    })}
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    userPosts: state.UserPosts.userPosts,
    userDetail: state.UserPosts.userDetail
});

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = dispatch => ({
    saveUserPosts: (data) => dispatch(UserPostsActions.saveUserPosts(data)),
    setUserDetail: (data) => dispatch(UserPostsActions.setUserDetail(data)),
    setLoading: (data) => dispatch(MainContainerActions.setLoading(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPostsContainer));