const breakpoints = {
  extraSmall: 360,
  small: 414,
  medium: 630,
  large: 960,
  iphone5: 320
};


// Used via Metrics.baseMargin
const metrics = {
  offset: {
    extremeSmall: 4,
    extraSmall: 8,
    small: 16,
    medium: 32,
    large: 44,
    extremeLarge: 74
  },
  web: {
    rootWidth: 960,
    blueBorderWidth: 4,
    headerHeight: 80,
    footerHeight: 60,
    navigationWidth: 300,
    mainContentWidth: 630,
    formItemWidth: 331,
    introHeight: 200
  },
  mobile: {
    blueBorderWidth: 2
  },
  zIndex: {
    // Manage zIndexes here
    rootLoadingMask: 5
  },
  activateButtonHeight: 35,
  marginHorizontal: 10,
  marginVertical: 10,
  marginMedium: 24,
  paddingWeb: 53,
  section: 25,
  baseMargin: 10,
  mediumBaseMargin: 15,
  doubleBaseMargin: 20,
  smallMargin: 5,
  horizontalLineHeight: 1,
  searchBarHeight: 30,
  buttonRadius: 4,
  tutorialRadius: 5,
  icons: {
    supertiny: 13,
    tiny: 15,
    small: 20,
    medium: 30,
    mediumLarge: 36,
    large: 45,
    xl: 60
  },
  images: {
    small: 24,
    medium: 40,
    large: 54,
    extraLarge: 80,
    logo: 300
  },
  designMobile: {
    width: 360,
    height: 640
  },
  designWeb: {
    width: 1440,
    height: 899
  },
  mobileForm: {
    paddingTop: 48,
    paddingLeft: 32,
    paddingRight: 32,
    paddingBottom: 32
  },
  slider: {
    thumb: {
      width: 20,
      height: 20,
      borderRadius: 15,
      borderWidth: 2
    },
    track: {
      height: 2,
      borderRadius: 2
    }
  },
  arrow: 12.5,
  // Single Screen-Sizes
  screenLarge:      `@media (min-width: ${breakpoints.large}px)`,
  screenMedium:     `@media (max-width: ${breakpoints.large - 1}px) and (min-width: ${breakpoints.medium}px)`,
  screenSmall:      `@media (max-width: ${breakpoints.medium - 1}px) and (min-width: ${breakpoints.small}px)`,
  screenExtraSmall: `@media (max-width: ${breakpoints.small - 1}px)`,
  // Ranges of Sizes
  screenMdLg:       `@media (min-width: ${breakpoints.medium}px)`,  // screenLarge + screenMedium
  screenXsSm:       `@media (max-width: ${breakpoints.medium - 1}px)`,  // screenSmall + screenExtraSmall
  screenXsSmMd:     `@media (max-width: ${breakpoints.large - 1}px)`,
  screenSmMd:       `@media (max-width: ${breakpoints.large - 1}px) and (min-width: ${breakpoints.small}px)`,
  screenSmMdLg:     `@media (min-width: ${breakpoints.small}px)`,
  screenXs:         `@media (max-width: ${breakpoints.extraSmall - 1}px)`,
  iphone5:          `@media (max-width: ${breakpoints.iphone5}px)`,
  breakpoints,
  // web screen
  webScreenHeight: window.innerHeight,
  webScreenWidth: window.innerWidth
};

export default metrics;
